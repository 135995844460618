export const getStyleObject = (string) => {
  let keyValues = null;

  if (string) {
    string = string.trim();

    if (string.charAt(string.length - 1) === ";") {
      string = string.replace(/;$/, "");

      let css_json = `{"${string
        .replace(/: /g, ":")
        .replace(/; /g, ";")
        .replace(/;/g, '", "')
        .replace(/:/g, '": "')}"}`;

      let obj = null;

      try {
        obj = JSON.parse(css_json);
      } catch (e) {}

      if (obj != null) {
        keyValues = Object.keys(obj).map((key) => {
          let camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
          return { [camelCased]: obj[key] };
        });
      }
    }
  }

  return keyValues != null ? Object.assign({}, ...keyValues) : null;
};
