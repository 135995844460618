import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useSlice } from "./slices";
import { getStyleObject } from "./utils";
import { CONTENTFUL_LOCALE } from "./constants";

const getStatusInfo = (item) => {
  function isDraft(entity) {
    return !entity.sys.publishedVersion;
  }
  function isChanged(entity) {
    return (
      !!entity.sys.publishedVersion &&
      entity.sys.version >= entity.sys.publishedVersion + 2
    );
  }
  function isPublished(entity) {
    return (
      !!entity.sys.publishedVersion &&
      entity.sys.version == entity.sys.publishedVersion + 1
    );
  }
  function isArchived(entity) {
    return !!entity.sys.archivedVersion;
  }
  return isDraft(item) ? (
    <div style={{ color: "orange", padding: "10px" }}>DRAFT</div>
  ) : isChanged(item) ? (
    <div style={{ color: "blue", padding: "10px" }}>CHANGED</div>
  ) : isPublished(item) ? (
    <div style={{ color: "green", padding: "10px" }}>PUBLISHED</div>
  ) : isArchived(item) ? (
    <div style={{ color: "red", padding: "10px" }}>ARCHIVED</div>
  ) : null;
};

const Asset = ({
  item,
  // :{
  //   fields: {
  //     file: {
  //       [CONTENTFUL_LOCALE]: { url },
  //     },
  //     description,
  //   },
  //   sys: { id },
  // }
  setSelectedAssetId,
  selectedAssetId,
  sliderWidth,
  isSliderDisabled,
  isFirst,
}) => {
  const imgRef = useRef(null);
  const [, setImgRef] = useSlice("imgRef");
  const [, setSelectedItemWidth] = useSlice("selectedItemWidth");
  const [, setIsResetBtnDisabled] = useSlice("isResetBtnDisabled");
  const [, setInitialWidths] = useSlice("initialWidths");
  const [, setSelectedImgRef] = useSlice("selectedImgRef");
  const [isSetOnlyOnce, setIsSetOnlyOnce] = useState(false);

  useEffect(() => {
    if (isFirst) {
      setImgRef(imgRef);
    }
  }, []);

  const isSelected = selectedAssetId === item?.sys?.id;

  const reselect = () => {
    setSelectedItemWidth(imgRef.current.getBoundingClientRect().width);
  };

  useEffect(() => {
    if (isSelected && !isSetOnlyOnce) {
      const elementWidth = imgRef.current.getBoundingClientRect().width;
      setInitialWidths((iW) => ({
        ...iW,
        [item?.sys?.id]: elementWidth,
      }));
      setIsSetOnlyOnce(true);
    }
  }, [item?.sys?.id, isSelected, isSetOnlyOnce]);

  useEffect(() => {
    if (isSelected) {
      const itemWidth = imgRef.current.getBoundingClientRect().width;
      setSelectedItemWidth(itemWidth);
    }
  }, [isSelected]);

  useEffect(() => {
    isSelected && setSelectedImgRef(imgRef);
  }, [isSelected]);

  useEffect(() => {
    if (isSelected && !isSliderDisabled) {
      imgRef.current.style.width = `${sliderWidth}px`;
    }
  }, [sliderWidth]);

  useEffect(() => {
    if (!!item?.fields?.description?.[CONTENTFUL_LOCALE]) {
      imgRef.current.style.width = getStyleObject(
        item?.fields?.description[CONTENTFUL_LOCALE]
      )?.width;
    } else {
      imgRef.current.style.width = "";
      if (isSelected) {
        reselect();
      }
    }
  }, [item?.fields?.description]);

  useEffect(() => {
    isSelected &&
      setIsResetBtnDisabled(!item?.fields?.description?.[CONTENTFUL_LOCALE]);
  }, [item?.fields?.description, isSelected]);

  return (
    <SupraContainer
      onClick={() =>
        isSelected ? reselect() : setSelectedAssetId(item?.sys?.id)
      }
    >
      <AssetContainer isSelected={isSelected}>
        <Image
          src={item?.fields?.file?.[CONTENTFUL_LOCALE]?.url}
          ref={imgRef}
        />
        <StatusInfo>{getStatusInfo(item)}</StatusInfo>
      </AssetContainer>
    </SupraContainer>
  );
};

const Image = styled.img``;

const SupraContainer = styled.div`
  cursor: pointer;
`;

const AssetContainer = styled(({ isSelected, ...props }) => <div {...props} />)`
  overflow: auto;
  padding: 10px;
  ${({ isSelected }) =>
    isSelected ? "border:3px solid red;" : "border: 1px solid black;"}
  border-radius: 10px;
  position: relative;
`;

const StatusInfo = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export default Asset;
