import styled from "styled-components";
import Field from "./Field";
import { CONTENTFUL_LOCALE } from "./constants";

const AssetInfo = ({
  item: {
    fields: {
      title: { [CONTENTFUL_LOCALE]: title_ },
      description,
    },
    sys: { id },
  },
}) => {
  return (
    <AssetInfoContainer>
      <Field name="Name" value={title_} />
      <Field name="Description" value={description?.[CONTENTFUL_LOCALE]} />
      <Field name="Id" value={id} />
    </AssetInfoContainer>
  );
};

const AssetInfoContainer = styled.div`
  align-self: stretch;
`;

export default AssetInfo;
