import styled from "styled-components";

const Field = ({ name, value }) => {
  return (
    <FieldContainer>
      <Name>{`${name}: `}</Name>
      <Value>{value}</Value>
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
`;

const Name = styled.div`
  white-space: pre;
  font-weight: 700;
`;
const Value = styled.div`
  margin-left: auto;
`;

export default Field;
