import { useEffect, useState, useMemo, useReducer } from "react";
import Asset from "./Asset";
import styled from "styled-components";
import AssetInfo from "./AssetInfo";
import { Slider as S, Button as B, Input, InputGroup as IG } from "rsuite";
import { useDebounce, useQuery } from "./hooks";
import { useSlice } from "./slices";
import SearchIcon from "@rsuite/icons/Search";
import { CONTENTFUL_LOCALE } from "./constants";
import { getAssets, getAsset, update, reset } from "./contentful";

const emptyItem = {
  fields: {
    description: {
      [CONTENTFUL_LOCALE]: "",
    },
    title: {
      [CONTENTFUL_LOCALE]: "",
    },
  },
  sys: {
    id: "",
  },
};

const NUMBER_OF_ITEMS_PER_PAGE = 10;

const App = () => {
  const queryParams = useQuery();
  const [data, setData] = useState();
  const [query, setQuery] = useState(queryParams.get("name") ?? "");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [selectedItemWidth, setSelectedItemWidth] =
    useSlice("selectedItemWidth");
  const [sliderWidth, setSliderWidth] = useState(selectedItemWidth);
  const [isButtonDisabled, setIsButtonDisabled] = useState(!selectedAssetId);
  const [page, setPage] = useState(1);
  const [isResetBtnDisabled, setIsResetBtnDisabled] =
    useSlice("isResetBtnDisabled");
  const [isSliderDisabled, setIsSliderDisabled] = useState(true);
  const [initialWidths, setInitialWidths] = useSlice("initialWidths");
  const [selectedImgRef] = useSlice("selectedImgRef");
  const [isUpdateInitialWidths, setIsUpdateInitialWidths] = useState(false);
  const [imgRef] = useSlice("imgRef");

  useEffect(() => {
    const fetchAssets = async () => {
      const data_ = await getAssets(debouncedQuery);
      setData(data_);
    };
    fetchAssets();
  }, [debouncedQuery]);

  const fetchAsset = async (assetId) => {
    const data = await getAsset(assetId);
    setData(({ items }) => {
      const newItems = [...items];
      const assetIndex = newItems.findIndex((itm) => itm.sys.id === assetId);
      newItems[assetIndex] = data.item;
      return { items: newItems };
    });
  };

  const updateAsset = async () => {
    setIsButtonDisabled(true);
    setIsSliderDisabled(true);
    setIsResetBtnDisabled(true);
    const data = await update(selectedAssetId, sliderWidth);
    if (data.status === "ok") {
      await fetchAsset(selectedAssetId);
    }
    setInitialWidths((iW) => ({ ...iW, [selectedAssetId]: sliderWidth }));
    setIsSliderDisabled(false);
  };

  const resetAsset = async () => {
    setIsButtonDisabled(true);
    setIsSliderDisabled(true);
    setIsResetBtnDisabled(true);
    const data = await reset(selectedAssetId);
    if (data.status === "ok") {
      await fetchAsset(selectedAssetId);
    }
    setIsUpdateInitialWidths(true);
    setSelectedItemWidth(selectedImgRef.current.getBoundingClientRect().width);
    setIsSliderDisabled(false);
  };

  const setDebouncedQueryDebounced = useDebounce(
    (query) => setDebouncedQuery(query),
    700
  );

  useEffect(() => {
    setDebouncedQueryDebounced(query);
  }, [query]);

  useEffect(() => {
    setSliderWidth(selectedItemWidth);
  }, [selectedItemWidth, selectedAssetId]);

  useEffect(() => {
    if (isUpdateInitialWidths) {
      setInitialWidths((iW) => ({
        ...iW,
        [selectedAssetId]: selectedItemWidth,
      }));
    }
  }, [isUpdateInitialWidths, selectedItemWidth, selectedAssetId]);

  useEffect(() => {
    setIsUpdateInitialWidths(false);
  }, [sliderWidth]);

  useEffect(() => {
    setIsButtonDisabled(
      !selectedAssetId || initialWidths[selectedAssetId] === sliderWidth
    );
  }, [selectedAssetId, initialWidths, sliderWidth]);

  useEffect(() => {
    setIsSliderDisabled(!selectedAssetId);
  }, [selectedAssetId]);

  const numOfItems = data?.items.length;
  const restItems = numOfItems % NUMBER_OF_ITEMS_PER_PAGE;

  const numOfPages = useMemo(() => {
    const numOfPages_ = Math.floor(numOfItems / NUMBER_OF_ITEMS_PER_PAGE);
    if (!!restItems) {
      return numOfPages_ + 1;
    }
    return numOfPages_;
  }, [numOfItems, restItems]);

  const index =
    page * NUMBER_OF_ITEMS_PER_PAGE > numOfItems
      ? (page - 1) * NUMBER_OF_ITEMS_PER_PAGE + restItems
      : page * NUMBER_OF_ITEMS_PER_PAGE;

  useEffect(() => {
    if (!!imgRef?.current?.getBoundingClientRect().width) {
      setSelectedAssetId(
        data?.items?.slice(NUMBER_OF_ITEMS_PER_PAGE * (page - 1), index)?.[0]
          ?.sys?.id
      );
    }
  }, [data, page, imgRef]);

  return (
    <AppContainer>
      <AssetsContainerWithPagination>
        <AssetsContainer>
          {data?.items
            .slice(NUMBER_OF_ITEMS_PER_PAGE * (page - 1), index)
            .map((item, index) => (
              <Asset
                key={item.sys.id}
                item={item}
                setSelectedAssetId={setSelectedAssetId}
                selectedAssetId={selectedAssetId}
                sliderWidth={sliderWidth}
                isSliderDisabled={isSliderDisabled}
                isFirst={index === 0}
              />
            ))}
        </AssetsContainer>
        <PaginationContainer>
          <PageButton
            appearance="primary"
            disabled={page === 1}
            onClick={() => {
              setPage(1);
              setSelectedAssetId("");
            }}
          >
            First
          </PageButton>
          <PageButton
            appearance="primary"
            disabled={page === 1}
            onClick={() => {
              setPage((p) => p - 1);
              setSelectedAssetId("");
            }}
          >
            Previous
          </PageButton>
          <PageNumber>
            Page {page} of {numOfPages}
          </PageNumber>
          <PageButton
            appearance="primary"
            disabled={page === numOfPages}
            onClick={() => {
              setPage((p) => p + 1);
              setSelectedAssetId("");
            }}
          >
            Next
          </PageButton>
          <PageButton
            appearance="primary"
            disabled={page === numOfPages}
            onClick={() => {
              setPage(numOfPages);
              setSelectedAssetId("");
            }}
          >
            Last
          </PageButton>
        </PaginationContainer>
      </AssetsContainerWithPagination>
      <InputContainer>
        <InputGroup inside onChange={(e) => setQuery(e.target.value)}>
          <Input value={query} />
          <InputGroup.Addon style={{ height: "100%" }}>
            <SearchIcon />
          </InputGroup.Addon>
        </InputGroup>
        <AssetInfo
          item={
            data?.items.find((item) => item.sys.id === selectedAssetId) ??
            emptyItem
          }
        />
        <Slider
          progress
          value={sliderWidth}
          onChange={(value) => setSliderWidth(value)}
          max={selectedItemWidth * 2}
          min={1}
          disabled={isSliderDisabled}
        />
        <Button
          onClick={updateAsset}
          appearance="primary"
          disabled={isButtonDisabled}
        >
          Save
        </Button>
        <Button
          onClick={resetAsset}
          appearance="primary"
          disabled={isResetBtnDisabled}
        >
          Reset
        </Button>
        <Tip>
          <strong>Tip:</strong> click again on the selected image to rebase the
          sliding bar
        </Tip>
      </InputContainer>
    </AppContainer>
  );
};

const AppContainer = styled.div`
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  height: 100vh;
`;

const AssetsContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 10px;
  padding-right: 10px;
  padding-bottom: 1px;
`;

const AssetsContainerWithPagination = styled.div`
  display: flex;
  flex-direction: column;
  flex: 70%;
  overflow: auto;
  gap: 10px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const PageButton = styled(B)``;

const PageNumber = styled.div``;

const InputContainer = styled.div`
  flex: 30%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 10px;
`;

// const Input = styled.input`
//   border-radius: 5px;
//   height: 40px;
//   width: 100%;
//   box-sizing: border-box;
// `;

const Slider = styled(S)`
  align-self: center;
  width: calc(100% - 10px);
`;

const Button = styled(B)`
  height: 40px;
`;
const InputGroup = styled(IG)`
  height: 40px;
  border-width: 2px;
`;

const Tip = styled.div``;

export default App;
