import { client, contenfulDetails } from "../client";

export const getAssets = async (query) => {
  try {
    const space = await client.getSpace(contenfulDetails.spaceId);
    const environment = await space.getEnvironment(
      contenfulDetails.environmentId
    );
    const assets = await environment.getAssets({
      query,
      // limit: MAX_NUMBER_OF_ITEMS,
    });
    return { items: assets.items };
  } catch (error) {
    console.error(error);
  }
};
