import { client, contenfulDetails, contentfulLocale } from "../client";

export const reset = async (id) => {
  try {
    const space = await client.getSpace(contenfulDetails.spaceId);
    const environment = await space.getEnvironment(
      contenfulDetails.environmentId
    );
    const asset = await environment.getAsset(id);
    if (asset.fields.description) {
      asset.fields.description[contentfulLocale] = "";
    } else {
      asset.fields.description = { [contentfulLocale]: "" };
    }
    await asset.update();
    const updatedAsset = await environment.getAsset(id);
    await updatedAsset.publish();
    return { status: "ok" };
  } catch (error) {
    console.error(error);
  }
};
