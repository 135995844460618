import { client, contenfulDetails } from "../client";

export const getAsset = async (id) => {
  try {
    const space = await client.getSpace(contenfulDetails.spaceId);
    const environment = await space.getEnvironment(
      contenfulDetails.environmentId
    );
    const asset = await environment.getAsset(id);
    return { item: asset };
  } catch (e) {
    console.error(e);
  }
};
