import getHookAndProviderFromSlices from "react-context-slices";

export const { useSlice, Provider } = getHookAndProviderFromSlices({
  slices: {
    selectedItemWidth: {},
    isResetBtnDisabled: { initialArg: true },
    initialWidths: { initialArg: {} },
    selectedImgRef: {},
    imgRef: {},
  },
});
