import { createClient } from "contentful-management";

const contentfulPAT = "CFPAT-Ykocf6UY2t2TdTrvUeJLJueKaUZCuBlO0Qd6972Fmxc";

export const client = createClient({
  accessToken: contentfulPAT,
});

const JMTestContentfulDetails = {
  spaceId: "4t6cvo9by44w",
  environmentId: "master",
};

const DevContentfulDetails = {
  spaceId: "2wexatmcd4kx",
  environmentId: "master",
};

export const contenfulDetails = DevContentfulDetails;

export const contentfulLocale = "es-ES";
